<template>
  <div class="flex-grow">
    <div class="flex items-center justify-center h-full">
      <Form @submit="handleRegister" :validation-schema="schema">
        <div class="m-auto pb-2 pt-1 px-4 rounded-3xl" style="max-width: 30rem; background: #63c289">
          <div class="card-body">
            <center>
              <h3>กรอกข้อมูลเพื่อสมัครสมาชิก</h3>
            </center>
            <div class="form-group row">
              <div class="col-sm-12">
                <Field name="name" type="text" class="form-control" placeholder="ชื่อ" />
                <ErrorMessage name="name" class="error-feedback danger" />
                <br />
              </div>
              <div class="col-sm-12">
                <Field name="lastname" type="text" class="form-control" placeholder="นามสกุล" />
                <ErrorMessage name="lastname" class="error-feedback danger" />
                <br />
              </div>
              <div class="col-sm-12">
                <Field name="phone" type="text" class="form-control" placeholder="เบอร์โทรศัพท์" />
                <ErrorMessage name="phone" class="error-feedback danger" />
                <br />
              </div>
              <div class="col-sm-12">
                <Field name="birthday" type="date" class="form-control" placeholder="วัน เดือน ปีเกิด" />
                <ErrorMessage name="birthday" class="error-feedback danger" />
                <br />
              </div>
              <div class="col-sm-12">
                <Field name="password" type="password" class="form-control" placeholder="ตั้งรหัสผ่าน" />
                <ErrorMessage name="password" class="error-feedback danger" />
                <br />
              </div>
              <p style="color: #d80007; font-weight: 500">
                - ต้องมีความยาวอย่างน้อย 6 ตัว<br />
                - ต้องประกอบไปด้วยตัวอักษรและตัวเลข
              </p>
              <div class="col-sm-12">
                <Field name="password_confirmation" type="password" class="form-control" placeholder="ยืนยันรหัสผ่านอีกครั้ง" />
                <ErrorMessage name="password_confirmation" class="error-feedback danger" />
              </div>
            </div>
            <div class="d-grid gap-2 mt-3">
              <button class="btn btn-secondary btn-lg" :disabled="loading">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span>สมัครสมาชิก</span>
              </button>
              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'register',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required('name is required!'),
      lastname: yup.string().required('lastname is required!'),
      phone: yup.string().required('phone is required!'),
      birthday: yup.string().required('birthday is required!'),
      password: yup.string().required('password is required!'),
      password_confirmation: yup.string().required('password confirm is required!')
    })

    return {
      loading: false,
      message: '',
      schema
    }
  },
  methods: {
    handleRegister(user) {
      this.loading = true

      this.$store.dispatch('auth/register', user).then(
        () => {
          this.$router.push('/getotp')
        },
        (error) => {
          this.loading = false
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        }
      )
    }
  }
}
</script>

<style scoped>
h3 {
  color: #fff;
}
.row {
  padding: 5%;
}
</style>
